<!-- =========================================================================================
  File Name: DataListThumbView.vue
  Description: Data List - Thumb View
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div id="data-list-thumb-view" class="data-list-container">
    <vs-table
      ref="table"
      multiple
      pagination
      search
      v-model="selected"
      :max-items="itemsPerPage"
      :data="stores"
    >
      <div
        slot="header"
        class="flex flex-wrap-reverse items-center flex-grow justify-between"
      >
        <div class="flex flex-wrap-reverse items-center">
          <!-- ACTION - DROPDOWN -->
          <vs-dropdown vs-trigger-click class="cursor-pointer mr-4 mb-4">
            <div
              class="
                p-4
                shadow-drop
                rounded-lg
                d-theme-dark-bg
                cursor-pointer
                flex
                items-center
                justify-center
                text-lg
                font-medium
                w-32
              "
            >
              <span class="mr-2">العمليات</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>
              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon
                    icon="ArchiveIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2"
                  />
                  <span>أرشفة</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon
                    icon="FileIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2"
                  />
                  <span>طباعة تقرير</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon
                    icon="CheckIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2"
                  />
                  <span> تفعيل</span>
                </span>
              </vs-dropdown-item>
              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon
                    icon="XIcon"
                    svgClasses="h-4 w-4"
                    class="mr-2"
                  />
                  <span>إلغاء التفعيل</span>
                </span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>

          <!-- ADD NEW -->
          <router-link :to="{ name: 'new-store' }">
            <vs-button
              class="
                btn-add-new
                p-3
                mb-4
                mr-4
                rounded-lg
                cursor-pointer
                flex
                items-center
                justify-center
              "
            >
              <feather-icon icon="PlusIcon" svgClasses="h-4 w-4" />
              <span class="ml-2">اضافة متجر</span>
            </vs-button>
          </router-link>
        </div>

        <!-- ITEMS PER PAGE -->

        <vs-dropdown
          vs-trigger-click
          class="cursor-pointer mb-4 mr-4 items-per-page-handler"
        >
          <div
            class="
              p-4
              border border-solid
              d-theme-border-grey-light
              rounded-full
              d-theme-dark-bg
              cursor-pointer
              flex
              items-center
              justify-between
              font-medium
            "
          >
            <span class="mr-2"
              >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
              {{
                stores.length - currentPage * itemsPerPage > 0
                  ? currentPage * itemsPerPage
                  : stores.length
              }}
              of {{ queriedItems }}</span
            >
            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
          </div>
          <vs-dropdown-menu>
            <vs-dropdown-item @click="itemsPerPage = 4">
              <span>4</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 10">
              <span>10</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 15">
              <span>15</span>
            </vs-dropdown-item>
            <vs-dropdown-item @click="itemsPerPage = 20">
              <span>20</span>
            </vs-dropdown-item>
          </vs-dropdown-menu>
        </vs-dropdown>
      </div>

      <template slot="thead">
        <vs-th>الصورة</vs-th>
        <vs-th sort-key="name">إسم المتجر</vs-th>
        <vs-th sort-key="phone">رقم الهاتف</vs-th>
        <vs-th sort-key="city">المدينة</vs-th>
        <vs-th sort-key="is_active">الحالة</vs-th>
        <vs-th>التفاصيل</vs-th>
      </template>

      <template slot-scope="{ data }">
        <tbody>
          <vs-tr :data="store" :key="index" v-for="(store, index) in data">
            <vs-td class="table-image-container">
              <img :src="store.logo" class="table-image rounded-lg" />
            </vs-td>

            <vs-td>
              <p class="product-name font-medium truncate">{{ store.name }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ store.phone }}</p>
            </vs-td>
            <vs-td>
              <p class="product-category">{{ store.city.name }}</p>
            </vs-td>

            <vs-td>
              <vs-switch
                @click="changeStatus(store.id, !store.is_active)"
                val="html"
                color="success"
                v-model="store.is_active"
              >
                <span slot="on">مفعل</span>
                <span slot="off">غير مفعل</span>
              </vs-switch>
            </vs-td>

            <vs-td>
              <vs-chip transparent class="product-order-status">
                <span @click="showProfile(store)">الملف الشخصي</span>
              </vs-chip>
            </vs-td>
          </vs-tr>
        </tbody>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  data() {
    return {
      search: "",
      selected: [],
      stores: [],
      isMounted: false,
      current_Page: 1,
      itemsPerPage: 5,
      lastPage: 0,
      totalItems: 0,
    };
  },
  computed: {
    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx;
      }
      return 0;
    },
    queriedItems() {
      return this.$refs.table
        ? this.$refs.table.queriedResults.length
        : this.stores.length;
    },
  },

  methods: {
    ...mapActions("stores", ["fetchStores", "updateStore"]),
    getData() {
      this.$vs.loading();

      this.fetchStores().then((response) => {
        this.stores = response.data.data;
        this.$vs.loading.close();
      });
    },
    showProfile(store) {
      this.$router.push({
        name: "store-profile",
        params: { id: store.id },
      });
    },
    changeStatus(id, status) {
      let data = new FormData();
      data.set("_method", "put");
      data.set("id", id);
      data.set("is_active", status ? 1 : 0);
      this.updateStore(data)
        .then(() => {
          this.getData();
          this.$vs.notify({
            title: "تم بنجاح",
            text: "تم تعديل حالة المتجر",
            color: "success",
          });
        })
        .catch((error) => {
          this.errorDialog(error);
        });
    },
  },
  mounted() {},
  created() {
    this.getData();
  },
};
</script>

<style lang="scss">
.product-img{
  max-width: 100px
}
#data-list-thumb-view {
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;

      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);

        td {
          padding: 10px;

          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }

          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }

          &.table-image-container {
            height: 110px;

            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .table-image {
              height: 68px;
            }
          }
        }

        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: uppercase;
          font-weight: 600;
        }
      }

      th.td-check {
        padding: 0 15px !important;
      }

      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}
</style>
